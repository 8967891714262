.info-back {
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1000;
}

.results {
  border-radius: 5px;
  margin-top: 40px;
}

.panelflotante {
  margin-top: 80px;
  border-radius: 5px;
  margin-bottom: 100px;
}

.background-transparent {
  background-color: transparent;
}
